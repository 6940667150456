import { Controller } from '@hotwired/stimulus'
import type Sortable from 'sortablejs'
import type { Options, SortableEvent } from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static values = {
    handle: String,
    url: String,
    draggableSelector: String,
  }

  declare sortableModule: typeof Sortable

  declare readonly urlValue: string
  declare readonly handleValue: string
  declare readonly draggableSelectorValue: string | undefined

  async connect(): Promise<void> {
    this.initializeSortable()
  }

  private async initializeSortable(): Promise<void> {
    if (!this.sortableModule) {
      await import('sortablejs').then(
        (module) => (this.sortableModule = module.default)
      )
    }

    const options: Options = {
      animation: 150,
      onEnd: (event: SortableEvent) => this.updatePosition(event),
      handle: this.handleValue,
    }

    if (this.draggableSelectorValue) {
      // Without this set, Sortable defaults to `li` if the element is an ol/ul, and all
      // direct children otherwise.
      options['draggable'] = this.draggableSelectorValue
    }

    new this.sortableModule(this.element as HTMLElement, options)
  }

  private async updatePosition(event: SortableEvent): Promise<void> {
    const url = this.urlValue
    const id = event.item?.dataset?.id

    if (url && id && event.newIndex !== event.oldIndex) {
      await this.sendRequest(url, id, event.newIndex ?? 0)
    }
  }

  private async sendRequest(
    url: string,
    id: string,
    newIndex: number
  ): Promise<void> {
    const replacedUrl = url.replace(':id', id)
    const newPosition = newIndex + 1

    try {
      await patch(replacedUrl, {
        body: JSON.stringify({
          position: newPosition,
        }),
      })
    } catch (error) {
      console.error('Error updating position:', error)
    }
  }
}
